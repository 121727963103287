<template>
  <div class="search">
    <el-form :model="form" class="demo-form-inline" size="small" inline>
      <div class="top">
        <el-form-item label="退款状态：" class="form-item">
          <el-select v-model="form.status" placeholder="请选择" class="form-item-select">
            <el-option label="全部" value=""></el-option>
            <el-option label="申请退款" value="1"></el-option>
            <el-option label="退款中" value="2"></el-option>
            <el-option label="已退款" value="3"></el-option>
            <el-option label="退款失败" value="5"></el-option>
            <el-option label="已拒绝退款" value="4"></el-option>
            <el-option label="已取消" value="7"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="支付流水号：" class="form-item">
          <el-input
            v-model="form.payOrderNo"
            placeholder="请输入"
            class="form-item-input"
          ></el-input>
        </el-form-item>
        <el-form-item label="订单类型：" class="form-item">
          <el-select v-model="form.orderType" placeholder="请选择" class="form-item-select">
            <el-option label="全部" value=""></el-option>
            <el-option label="收款订单" value="0"></el-option>
            <el-option label="加购订单" value="1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="订单ID：" class="form-item">
          <el-input v-model="form.orderNo" placeholder="请输入" class="form-item-input"></el-input>
        </el-form-item>
        <el-form-item class="form-item" label-width="30px">
          <el-link type="primary" style="margin-right: 10px" @click="checkFold"
            >{{ fold ? '收起' : '展开' }}
          </el-link>
          <el-button icon="el-icon-search" type="primary" size="small" @click="onSubmit"
            >查询</el-button
          >
          <el-button icon="el-icon-refresh-left" size="small" @click="reset">重置</el-button>
        </el-form-item>
      </div>

      <div v-show="fold" class="more">
        <el-form-item label="审核人：" class="form-item">
          <el-input
            v-model="form.verifyUserName"
            placeholder="请输入"
            class="form-item-input"
          ></el-input>
        </el-form-item>

        <el-form-item label="申请时间：">
          <el-date-picker
            v-model="form.time1"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            style="width: 340px"
          >
          </el-date-picker>
        </el-form-item>

        <el-form-item label="审核时间：">
          <el-date-picker
            v-model="form.time2"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            style="width: 340px"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="退款时间：">
          <el-date-picker
            v-model="form.time3"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            style="width: 340px"
          >
          </el-date-picker>
        </el-form-item>
      </div>
    </el-form>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  name: 'PageHeader',
  components: {},
  data() {
    return {
      form: {
        status: ''
      },
      fold: false,
      tags: [{ name: '标签一' }, { name: '标签二' }]
    };
  },
  mounted() {
    this.form.status = this.$route.query.status;
  },
  methods: {
    onSubmit() {
      const data = { ...this.form };
      if (data.time1) {
        data.createStartTime = moment(data.time1[0]).format('YYYY-MM-DD HH:mm:ss');
        data.createEndTime = moment(data.time1[1]).format('YYYY-MM-DD HH:mm:ss');
      }
      if (data.time3) {
        data.refundStartTime = moment(data.time3[0]).format('YYYY-MM-DD HH:mm:ss');
        data.refundEndTime = moment(data.time3[1]).format('YYYY-MM-DD HH:mm:ss');
      }
      if (data.time2) {
        data.verifyStartTime = moment(data.time2[0]).format('YYYY-MM-DD HH:mm:ss');
        data.verifyEndTime = moment(data.time2[1]).format('YYYY-MM-DD HH:mm:ss');
      }
      delete data.time1;
      delete data.time2;
      delete data.time3;
      this.$emit('search', data);
    },
    reset() {
      this.form = {};
      this.$emit('search', this.form);
    },
    checkFold() {
      this.fold = !this.fold;
    }
  }
};
</script>
<style lang="less" scoped>
@import './search.less';
.el-dialog {
}
::v-deep.el-form-item__label {
  margin-right: 10px !important;
}
</style>
