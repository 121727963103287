<template>
  <section class="refund-records-wrapper">
    <search @search="search" />
    <section class="refund-table">
      <vxe-grid ref="xTable" v-loading="loading" border :data="tableData" :columns="tableColumn" size="small" v-bind="tableOptions">
        <template #amount="{ row }">
          <span>¥{{ row.amount }}</span>
        </template>
        <template #payAmount="{ row }">
          <span>¥{{ row.payAmount }}</span>
        </template>
        <template #toolbar_buttons>
          <el-button size="small" type="default" @click="handleExport">导出</el-button>
          <!-- <el-button size="small" type="default">导出</el-button> -->
          <el-button
            v-if="powerList.find((e) => e.nodeCode == 'YY_PAYCENTER_AGREEREFUND')"
            size="small"
            type="default"
            @click="refund('批量', 2)"
            >同意退款</el-button
          >
          <el-button
            v-if="powerList.find((e) => e.nodeCode == 'YY_PAYCENTER_REFUSEREFUND')"
            size="small"
            type="default"
            @click="refund('批量', 4)"
            >拒绝退款</el-button
          >
        </template>
        <template #orderType="{ row }">
          <span>{{ row.orderType == 0 ? '收款订单' : '加购订单' }}</span>
        </template>
        <template #orderNo="{ row }">
          <el-link :underline="false" type="primary" @click="goOrderDetail(row)">{{ row.orderNo }}</el-link>
        </template>
        <template #payOrderNo="{ row }">
          <el-link :underline="false" type="primary" @click="goPayDetail(row)">{{ row.payOrderNo }}</el-link>
        </template>
        <template #status="{ row }"
          ><span :style="{ color: getStatusColor(row.status) }">
            {{ getStatusName(row.status) }}
          </span>
        </template>
        <template #operate="{ row }">
          <el-button v-if="powerList.find((e) => e.nodeCode == 'YY_PAYCENTER_REFUNDDETAIL')" type="text" @click="detail(row)">详情</el-button>
          <!-- <el-button v-if="row.status === 5" type="text" @click="refund(row, 5)">退款</el-button>
          <el-button v-if="row.status === 5" type="text" @click="refund(row, 6)"
            >确认线下退款</el-button
          > -->
          <el-button
            v-if="
              row.status === 1 && powerList.find((e) => e.nodeCode == 'YY_PAYCENTER_AGREEREFUND')
            "
            type="text"
            @click="refund(row, 2)"
            >同意退款</el-button
          >
          <el-button
            v-if="
              row.status === 1 && powerList.find((e) => e.nodeCode == 'YY_PAYCENTER_REFUSEREFUND')
            "
            type="text"
            @click="refund(row, 4)"
            >拒绝退款</el-button
          >
        </template>
        <template #pager>
          <i-pagination
            :page-size="iPagination.pageSize"
            :current-page="iPagination.pageNumber"
            :page-sizes="[10, 20, 30, 40]"
            :total="total"
            @current-change="handlePageChange"
            @size-change="handleSizeChange"
          />
        </template>
      </vxe-grid>
    </section>
    <RefundModal :status="status" :dialog-form-visible="dialogFormVisible" :title="title" :row="rowData" :select-records="selectRecords" @cancel="cancel" @getTable="getTable" />
  </section>
</template>

<script>
import Search from './components/search/index.vue'
import RefundModal from '@/components/refundModal/index.vue'
import IPagination from '@/components/i-pagination/index.vue'
import { getRefundPgRecorList, dealRefundRecord, refundExport } from '@/api/pay'
import { downloadFile } from '@/utils/fileDownload'

export default {
  name: 'RefundRecords',
  components: {
    Search,
    IPagination,
    RefundModal,
  },
  data() {
    return {
      iPagination: {
        pageSize: 10,
        pageNumber: 1,
        data: {},
      },
      loading: false,
      total: 0,
      tableColumn: [
        { type: 'checkbox', minWidth: 80, fixed: 'left' },
        { field: 'refundNo', title: '退款申请编号', fixed: 'left', minWidth: 220 },
        {
          field: 'payOrderNo',
          minWidth: 220,
          title: '支付流水号',
          slots: { default: 'payOrderNo' },
        },
        { field: 'orderNo', minWidth: 220, title: '订单ID', slots: { default: 'orderNo' } },
        { field: 'orderType', title: '订单类型', slots: { default: 'orderType' }, minWidth: 150 },
        { field: 'payAmount', title: '应退金额', minWidth: 150, slots: { default: 'payAmount' } },
        { field: 'amount', title: '退款金额', minWidth: 150, slots: { default: 'amount' } },
        { field: 'createTime', title: '申请时间', minWidth: 150 },
        { field: 'status', title: '退款状态', slots: { default: 'status' }, minWidth: 150 },
        { field: 'verifyUserName', title: '审核人', minWidth: 150 },
        { field: 'verifyTime', title: '审核时间', minWidth: 150 },
        { field: 'refundTime', title: '退款时间', minWidth: 150 },
        { title: '操作', slots: { default: 'operate' }, fixed: 'right', minWidth: 200 },
      ],
      dialogFormVisible: false,
      tableData: [],
      title: '',
      tableOptions: {
        toolbarConfig: {
          slots: {
            // 自定义工具栏模板
            buttons: 'toolbar_buttons',
          },
        },
      },
      status: 0,
      selectRecords: [],
      rowData: {},
    }
  },
  computed: {
    powerList: function () {
      return this.$store.getters.getPowerList
    },
  },
  mounted() {
    this.iPagination.data.status = this.$route.query.status
    this.getTable()
  },
  methods: {
    getStatusName(e) {
      switch (e) {
        case 1:
          return '申请退款';
        case 2:
          return '退款中';
        case 3:
          return '已退款';
        case 4:
          return '已拒绝退款';
        case 5:
          return '退款失败';
        case 7:
          return '已取消';
        default:
          return '--'
      }
    },
    getStatusColor(e) {
      switch (e) {
        case 1:
          return '#d9001b';
        case 2:
          return '#02a7f0';
        case 3:
          return '#70b603';
        case 4:
          return '#f59a23';
        case 5:
          return '#d9001b';
        case 7:
          return '#aaaaaa';
        default:
          return '--'
      }
    },
    goOrderDetail(row) {
      let url = ''
      if (row.orderType === 0) {
        url = '/index/service/receivingOrder/receivingParticulars?id='
      } else {
        url = '/index/service/purchaseOrder/purchaseParticulars?id='
      }
      history.pushState(null, null, url + row.orderId)
    },
    goPayDetail(row) {
      this.$router.push({
        path: '/record/detail',
        query: { id: row.payOrderNo, orderNo: row.orderNo },
      })
    },
    search(data) {
      this.iPagination.pageNumber = 1
      this.iPagination.data = { ...data }
      this.getTable()
    },
    handlePageChange(val) {
      this.iPagination.pageNumber = val
      this.getTable()
    },
    handleSizeChange(val) {
      this.iPagination.pageSize = val
      this.getTable()
    },
    getTable() {
      this.loading = true
      getRefundPgRecorList(this.iPagination).then((res) => {
        this.loading = false
        this.tableData = res.content
        this.total = Number(res.total)
      })
    },
    detail(row) {
      this.$router.push({
        path: '/refund/detail',
        query: { refundId: row.refundId },
      })
    },

    cancel() {
      this.dialogFormVisible = false
    },
    handleExport() {
      this.loading = true
      const data = JSON.parse(JSON.stringify(this.iPagination))
      data.data.isExport = 1
      refundExport(data)
        .then((res) => {
          downloadFile(res, '退款记录', '.xlsx')
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
    refund(row, status) {
      if (row === '批量') {
        this.selectRecords = this.$refs.xTable.getCheckboxRecords()
        if (this.selectRecords.length === 0) {
          this.$message({
            message: '请至少选中一条数据',
            type: 'warning',
          })
          return
        }
      }
      let name = ''
      this.rowData = row
      this.status = status
      switch (status) {
        case 2:
          name = '同意退款';
          break;
        case 6:
          name = '线下退款';
          break;
        case 4:
          name = '拒绝退款';
          break;
        case 5:
          name = '退款'
          break
        default:
          name = ''
      }
      this.title = name
      if (status === 5) {
        this.$confirm(`是否确认${this.title}?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            const data = {
              refundIds: [this.rowData.refundId],
              status: this.status,
            }
            dealRefundRecord({ data }).then(() => {
              this.$message({
                type: 'success',
                message: '操作成功!',
              })
              this.getTable()
            })
          })
          .catch(() => {})
      } else {
        this.dialogFormVisible = true
      }
    },
  },
}
</script>

<style lang="less" scoped>
@import './index.less';
</style>
